<template>
	<Metier name="disponibilite-et-methodes-generales" title="Disponibilité et Méthodes Générales" :main-menu="[{title: 'Contremaître Méthodes Générales', missions: ['S\'assure de la bonne application du processus de travaux', 'Met à jour les processus de travaux', 'Forme les effectifs au processus de travaux'], competences: ['Formation Technique', 'Expérience en Maintenance Courante', 'Maîtrise des outils informatiques'], qualites: ['Rigueur', 'Autonomie', 'Sens du contact']}, {title: 'Technicien Coût Chiffrage', missions: ['Assure le suivi des contrats de maintenance', 'Réalise le chiffrage des coûts avant la réalisation des travaux des différents services', 'Vérifie la conformité des coûts des travaux engagés par rapport aux prévisions'], competences: ['Expérience en Maintenance dans le milieu pétrolier', 'Connaissance des procédures administratives', 'Maîtrise des outils informatiques et des techniques de chiffrage'], qualites: ['Rigueur', 'Organisation', 'Sens du contact']}]">
	</Metier>
</template>

<script>
	export default {
		components: {
			Metier: () => import('@/components/Metier.vue'),
		},
	}
</script>
